/**
 * Paragraph block
 */

import React from 'react'

const Paragraph = ({ data }: any) => {
  return <div dangerouslySetInnerHTML={{ __html: data }} />
}
export default Paragraph
