import React from 'react'
import Embed from './Embed'
import Emphasize from './Emphasize'
import GutenbergGallery from './GutenbergGallery'
import GutenbergImage from './GutenbergImage'
import Heading from './Heading'
import Paragraph from './Paragraph'

export const Gutenberg = ({ blocks }: any) => {
  return (
    <div className="gutenberg mx-auto max-w-6xl space-y-8 mb-24">
      {blocks?.map((block: any) => {
        switch (block.name) {
          case 'core/image':
            return <GutenbergImage key={block.order} data={block} />
          case 'core/gallery':
            return <GutenbergGallery key={block.order} data={block} />
          case 'core/paragraph':
          case 'core/list':
            return <Paragraph key={block.order} data={block?.originalContent} />
          case 'core/heading':
            return <Heading key={block.order} data={block?.originalContent} />
          case 'core/embed':
            return <Embed key={block.order} data={block} />
          case 'carbon-fields/emphasize':
            return <Emphasize key={block.order} data={block} />
        }
      })}
    </div>
  )
}
