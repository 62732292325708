/**
 * Embed block
 */
import React, { useEffect, useState } from 'react'

const Embed = ({ data }: any) => {
  const attributes = data?.attributesJSON && JSON.parse(data.attributesJSON)

  const [html, setHtml] = useState('')

  useEffect(() => {
    const url = `https://youtube.com/oembed?url=${attributes?.url}&maxwidth=960&maxheight=560`

    const fetchData = async () => {
      try {
        const response = await fetch(url)
        const json = await response.json()
        const html = json.html.replace('youtube', 'youtube-nocookie')
        setHtml(html)
      } catch (error) {
        console.log('error', error)
      }
    }

    fetchData()
  }, [attributes?.url])

  return <div className="w-full video-embed" dangerouslySetInnerHTML={{ __html: html }} />
}

export default Embed
