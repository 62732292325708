/**
 * Emphasize block
 */

import React from 'react'

const Emphasize = ({ data }: any) => {
  const attributes = data?.attributesJSON
  const content = attributes && JSON.parse(attributes)?.data?.emphasize

  return (
    <>
      {content && (
        <span className="text-3xl md:text-5xl lg:text-7xl font-serif font-medium lg:text-center block lg:max-w-2xl lg:mx-auto text-primary/80">
          {content}
        </span>
      )}
    </>
  )
}
export default Emphasize
