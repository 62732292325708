/**
 * Paragraph block
 */

import React from 'react'

const Heading = ({ data }: any) => {
  return <div className="-mb-8 font-serif" dangerouslySetInnerHTML={{ __html: data }} />
}
export default Heading
