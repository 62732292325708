import React from 'react'
import GutenbergImage from './GutenbergImage'
const GutenbergGallery = ({ data }: any) => {
  const images = data?.innerBlocks
  const attributes = data?.attributesJSON && JSON.parse(data.attributesJSON)

  let classes

  switch (attributes?.columns) {
    case 1:
      classes = 'md:grid-cols-2'
      break
    case 2:
      classes = 'md:grid-cols-2'
      break
    case 3:
      classes = 'md:grid-cols-3'
      break
    case 4:
      classes = 'md:grid-cols-3'
      break
    default:
      classes = 'md:grid-cols-2'
      break
  }

  return (
    <div className={`grid gap-4 grid-cols-1 ${classes}`}>
      {images?.map((image: any, idx: number) => {
        return <GutenbergImage className="grid place-content-center place-items-center" key={idx} img={image} />
      })}
    </div>
  )
}

export default GutenbergGallery
