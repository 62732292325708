import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
const GutenbergImage = ({ data, img, className }: { data?: any; img?: any; className?: string }) => {
  if (!img?.mediaItem?.node?.localFile && !data?.mediaItem?.node?.localFile) return <></>

  const image = getImage(img?.mediaItem?.node?.localFile) || getImage(data.mediaItem.node.localFile)
  return (
    <div className={`mx-auto ${className}`}>{image && <GatsbyImage className="w-full" alt="" image={image} />}</div>
  )
}

export default GutenbergImage
